<template>
  <div class="table-form">
    <a-form layout="inline" ref="formRef" :model="formState">
      <a-form-item label="操作模块：" name="applyStatus" class="form-center">
        <a-select v-model:value="formState.module" allowClear placeholder="请选择" class="form-select">
          <a-select-option v-for="value in moduleEnums" :key="value.id" :value="value.code">{{
            value.desc
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="操作人姓名：">
        <a-input
          ref="inputbox"
          class="form-input"
          allowClear
          :maxlength="20"
          v-model:value="formState.operatorName"
          placeholder="请输入操作人姓名"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="操作内容：">
        <a-input
          ref="inputbox"
          class="form-input"
          allowClear
          :maxlength="20"
          v-model:value="formState.searchContent"
          placeholder="请输入操作内容"
        >
        </a-input>
      </a-form-item>

      <!-- 提交时间 -->
      <a-form-item label="操作时间：" name="submitTime">
        <a-range-picker separator="→" allowClear v-model:value="formState.submitTime">
          <template #suffixIcon>
            <CalendarOutlined />
          </template>
        </a-range-picker>
      </a-form-item>
      <a-form-item class="form-btns">
        <a-button @click="reset">重置</a-button>
        <a-button @click="searchList" class="form-btn">查询</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue'
import { CalendarOutlined } from '@ant-design/icons-vue'
import { getEnums } from '@/apis/user'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const emit = defineEmits(['update:formData', 'searchList', 'reset', 'toDetail'])

const formRef = ref(null)

const moduleEnums = ref([])

const formState = computed({
  get: () => props.formData,
  set: val => emit('update:formData', val)
})

const searchList = () => {
  emit('searchList')
}

const reset = () => {
  formRef.value.resetFields()
  emit('reset')
}

const getModuleEnums = async () => {
  await getEnums({ kind: 'OPERATION_LOG_MODULE' }).then(res => {
    if (res.success) {
      moduleEnums.value = res.data
      console.log('res.data的值', res.data)
    }
  })
}

onMounted(() => {
  getModuleEnums()
})
</script>
<style lang="less" scoped>
@import url(./index.less);
</style>
